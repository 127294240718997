// centralizacja hooków do pobierania pewnych informacji z reduxa

import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

import type { ActProgram } from "utils/epr/actModel";
import type { Dictionary, FolksUser, SowaUser } from "utils/types";
import { useDerivedState } from "./derived";

// TODO: otypować wg. dokumentacji API

export interface CurrentSession {
  // na podstawie sessionReducera, typy mogą nie być 100% poprawne
  isUserLoggedIn: boolean
  anonymousSession: boolean
  session_id: null | string
  session_key: null | string
  sessionRole: string
  sessionExpired: boolean
  sessionError: any
  sessionLoading: boolean
  sessionMessage: any
  logoutUrl: any
}

export interface CurrentSessionFull extends CurrentSession {
  expires_at: null | Date
  activity: any
  timeout: number
}

export interface CurrentUser {
  readonly folks: FolksUser
  
  readonly sowa: Dictionary<CurrentSowaUser>
  
  readonly teka?: {
    readonly user_id: string
    readonly is_editor: string
  }
  
  // do usunięcia jak się pozbędziemy reduxa
  readonly error?: any
  readonly loading?: any
}

// to jest połączenie UserGet i UserDef, zawiera też ConfigInfo, ale tutaj nie eksponujemy
export interface CurrentSowaUser extends SowaUser {
  readonly error?: number // status komendy UserGet
  
  /** uprawnienia w formie obiektu do łatwego sprawdzania */
  readonly rightsMap: CurrentSowaUserRightsMap;
  
  // do usunięcia lub zmiany
  
  readonly profiles: readonly string[]
  readonly rights: readonly CurrentSowaUserRight[];
}

export interface CurrentSowaUserRight {
  readonly right: string
  readonly desc: string
  readonly with_attrib: boolean
  readonly multichoice?: boolean
  
  readonly choices?: readonly CurrentSowaUserRightValue[];
  
  // parseSowaAttributes tego potworka tworzy
  value: null | string | undefined | CurrentSowaUserRightValue | readonly CurrentSowaUserRightValue[];
}

export interface CurrentSowaUserRightsMap {
  /** Modyfikowanie rekordów */
  readonly modyfikowanie?: string
  
  /** Modyfikowanie kolekcji */
  readonly kolekcje?: readonly string[]
  
  /** Ograniczenie edycji rekordów */
  readonly ograniczenie?: readonly string[]
  
  /** Ograniczenie do inwentarzy */
  readonly prefiks?: readonly string[]
  
  /** Wypożyczanie (główna agenda) */
  readonly wypożyczanie?: string
  
  /** Wypożyczanie w innych agendach */
  readonly inne?: readonly string[]
  
  /** Modyfikowanie naliczeń i wpłat */
  readonly opłaty?: readonly string[]
  
  readonly ubytkowanie?: readonly string[]
  
  /** Obsługa skontrum */
  readonly skontrum?: readonly string[]
  
  /** Obsługa czytelni (główna agenda) */
  readonly czytelnia?: string
  
  /** Obsługa innych czytelni */
  readonly czytelnie?: readonly string[]
  
  /** Obsługa stanowisk komputerowych (główna pracowania) */
  readonly stanowiska?: string
  
  /** Obsługa stanowisk komputerowych w innych pracowniach */
  readonly pracownie?: readonly string[]
  
  /** Zamówienia jako filia */
  readonly filia?: readonly string[]
  
  /** Tworzenie dokumentów wpływu */
  readonly akcesja?: readonly string[]
  
  /** Kod operatora kasy */
  readonly kasjer?: string
  
  /** Drukowanie rewersów */
  readonly rewersy?: readonly string[]
  
  /** Dostęp do raportów */
  readonly raporty?: readonly string[]
  
  readonly książkomat?: readonly string[]
  
  readonly administrowanie?: true
}

export interface CurrentSowaUserRightValue {
  readonly name: string
  readonly value: string
}

export interface SowaConfigInfo {
  readonly version: {
    readonly strix: {
      readonly ver: string
      readonly date: string
    }
    readonly sowa: {
      readonly ver: string
      readonly date: string
    }
  }
  readonly licence: {
    readonly no: number
    readonly date: string
    readonly name: string
  }
  readonly services: {
    readonly lending: boolean
    readonly booking: boolean
    readonly accession: boolean
    readonly ag_size: number
  }
  readonly profile: {
    readonly name: string
    readonly profile: {
      readonly orders: readonly SowaRecordIndexDef[]
      readonly patron_orders: readonly SowaPatronIndexDef[]
      readonly reports: readonly SowaReportDef[]
      readonly criteria: readonly SowaCriteriaDef[]
      readonly programs: readonly SowaProgramDef[]
      readonly formats: readonly SowaFormatDef[]
      readonly sort_programs: readonly SowaSortProgramDef[]
    }
  }
  readonly editor: readonly SowaRecordTypeDef[]
  readonly client: {
    readonly reports: readonly SowaClientReportDef[]
  }
  readonly khw: any
}

export interface SowaReportDef {
  readonly idf: string
  readonly desc: string
  readonly lang: any
  readonly icon?: string
  readonly save?: {
    readonly desc: string
    readonly ext: string
  }
  readonly api_cmd?: string
  readonly type?: string
  readonly group?: string | any
  readonly app?: readonly string[]
  readonly mode: string
}

export interface SowaProgramDef {
  readonly api_cmd?: string
  readonly opts?: string
  readonly desc: string
  readonly idf: string
  readonly group?: string | any
  readonly app?: readonly string[]
  readonly call?: string
  readonly after: string
}

export interface SowaFormatDef {
  readonly idf: string
  readonly desc: string
  readonly name: string
}

export interface SowaSortProgramDef {
  readonly idf: string
  readonly desc: string
}

export interface SowaClientReportDef {
  readonly idf: string
  readonly kind: string
  readonly desc: string
}

export interface SowaCriteriaDef {
  readonly idf: string
  readonly desc: string
  readonly opts?: string
}

/** Definicja dostępnego na serwerze indeksu sortowania/przeszukiwania rekordów czytelników */
export interface SowaPatronIndexDef {
  readonly idf: string
  readonly name: string
}

/** Definicja dostępnego na serwerze indeksu sortowania/przeszukiwania rekordów */
export interface SowaRecordIndexDef {
  readonly idf: string
  readonly name: string
}

export interface SowaRecordTypeDef {
  readonly rec_type: string
  readonly description: string
  readonly epr: string
  readonly act: "" | ActProgram 
}

// to niestety ciągle będzie się budzić z nową wartością z uwagi na przedłużanie ważności sesji
export function useCurrentSessionFull() {
  return useSelector(currentSessionSelector) as CurrentSessionFull;
}

export function useCurrentSession() {
  return useSelector(currentSessionPartialSelector, isEqual) as CurrentSession;
}

export function useCurrentSessionRole() {
  return useSelector(currentSessionRoleSelector) as CurrentSession["sessionRole"];
}

export function useCurrentSessionLoggedIn() {
  return useSelector(currentSessionLoggedInSelector) as CurrentSession["isUserLoggedIn"];
}

export function useCurrentUser() {
  return useSelector(currentUserSelector) as CurrentUser;
}

export function useCurrentUserFolks() {
  return useSelector(currentUserFolksSelector) as CurrentUser["folks"];
}

export function useCurrentUserId() {
  return useSelector(currentUserIdSelector) as CurrentUser["folks"]["user_id"];
}

export function useCurrentUserFolksRole() {
  return useSelector(currentUserFolksRoleSelector) as CurrentUser["folks"]["role"];
}

export function useCurrentUserSowa(catalogueId: string) {
  return useCurrentUser().sowa[catalogueId] as CurrentUser["sowa"][""] | undefined;
}

export function useSowaConfigInfo(catId: string) {
  const selector = useDerivedState(makeConfigInfoSelector, catId);
  return useSelector(selector) as SowaConfigInfo | undefined;
}

const makeConfigInfoSelector = (catId: string) => (state: any) => state.currentUser.sowa[catId]?.configInfo;

const currentSessionSelector = (state: any) => state.session;
const currentSessionRoleSelector = (state: any) => state.session.sessionRole;
const currentSessionLoggedInSelector = (state: any) => state.session.isUserLoggedIn;
const currentSessionPartialSelector = ({ session }: any) => ({
  isUserLoggedIn: session.isUserLoggedIn,
  anonymousSession: session.anonymousSession,
  session_id: session.session_id,
  session_key: session.session_key,
  sessionRole: session.sessionRole,
  sessionExpired: session.sessionExpired,
  sessionError: session.sessionError,
  sessionLoading: session.sessionLoading,
  sessionMessage: session.sessionMessage,
  logoutUrl: session.logoutUrl,
});

const currentUserSelector = (state: any) => state.currentUser;
const currentUserFolksSelector = (state: any) => state.currentUser.folks;
const currentUserIdSelector = (state: any) => state.currentUser.folks.user_id;
const currentUserFolksRoleSelector = (state: any) => state.currentUser.folks.role;
